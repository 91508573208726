import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Typography } from 'antd';
import query_chatReportOverviewRateOnTimeReponsesDetail from 'graphql/queries/query_chatReportOverviewRateOnTimeReponsesDetail';

const STATUS = {
    DISCONNECT: 0,
    CONNECTED: 1,
    LOST_CONNECTED: 2,
}

const generateDateDefault = (range: number = 7, showHours: boolean = false) => {
    return {
        from: dayjs().subtract(range, 'day').startOf('day').unix(),
        to: dayjs().subtract(showHours ? 0 : 1, 'day').endOf('day').unix(),
    }
};

const { Text } = Typography

interface StoreProps {
    status: number,
    name: string,
    logo: string,
    timeExpired?: string,
    connector_channel_code: string,
    authorization_expired_at: string,
}

const Alert = () => {
    const [stores, setStores] = useState<StoreProps[]>([{
        status: 0,
        name: "",
        logo: "",
        timeExpired: "",
        connector_channel_code: "",
        authorization_expired_at: ""
    }])

    // const dispatch = useDispatch()
    // function timeStoreExpired(time) {
    //     return {
    //         days: Math.floor((dayjs(time).unix() - dayjs().unix()) / (60 * 60 * 24)),
    //         hours: Math.floor(((dayjs(time).unix() - dayjs().unix()) % (60 * 60 * 24)) / (60 * 60))
    //     }
    // }

    // function amountDay(time) {
    //     if (timeStoreExpired(time)?.days === 0) {
    //         return timeStoreExpired(time)?.hours < 10 ?
    //             formatMessage({ defaultMessage: "0{time} giờ" }, { time: timeStoreExpired(time)?.hours })
    //             : formatMessage({ defaultMessage: "{time} giờ" }, { time: timeStoreExpired(time)?.hours })
    //     }
    //     return formatMessage({ defaultMessage: "{time} ngày" }, { time: timeStoreExpired(time)?.days })
    // }
    useQuery(query_chatReportOverviewRateOnTimeReponsesDetail, {
        fetchPolicy: "cache-and-network",
        variables: {
            ...generateDateDefault(7),
        },
        onCompleted: (data) => {
            const channels = data?.op_connector_channels?.map(channel => {
                return {
                    logo: channel?.logo_asset_url,
                    code: channel?.code
                }
            })

            const storeError = data?.chatReportOverviewRateOnTimeReponsesDetail?.map(item => {
                const channel = channels?.find(cn => cn?.code == item?.channelCode)
                const store = data?.sc_conversation_stores?.find(st => st?.id == item?.conversationStoreId)
                return {
                    ...store,
                    logo: channel?.logo,
                }
            })
            setStores(storeError)
        }
    });

    return (
        <>
            <div className='mb-2'>
                {stores?.map((store, index) => (
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background: '#f5c6cb',
                        color: '#721c24',
                        alignItems: 'center',
                        padding: '7px 7px',
                        marginBottom: '2px',
                        borderRadius: '5px'
                    }}
                        key={index}
                    >
                        <Flex align='center' justify='center' gap={10} style={{ flex: 1 }}>
                            {/* <img src={toAbsoluteUrl("/media/warningsvg.svg")} alt=""></img> */}

                            <Text>Tỷ lệ phản hồi của gian hàng</Text>
                            <img src={store?.logo} style={{ width: 16, height: 16, borderRadius: 4 }} />
                            <Text>{store?.name} đang ở dưới mức 85%. Vui lòng phản hồi người mua đúng hạn để nâng cao tỷ lệ phản hồi".</Text>

                        </Flex>
                        <div onClick={() => setStores((prev) => prev.slice(0, -1))} style={{ cursor: 'pointer' }}>
                            <X />
                        </div>
                    </div>
                )
                )}
            </div>
        </>
    )
}

const X = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#595959" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>

export default Alert