import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ArrowUpOutlined,
    SettingOutlined,
    BankOutlined
} from '@ant-design/icons';
import { Layout, BackTop, Affix, Flex, Button, Menu } from 'antd';
import React, { ReactNode, useMemo, useState } from 'react';
import BreadcrumbList from './Breadcrumb/index';
import LayoutWrapper from './Layout.styles';
import MenuLayout from './Menu/index';
import { LayoutProvider } from '../../contexts/LayoutContext';
import HeaderLayout from './Header';
import { useGlobalSliceSlice } from 'app/slice';
import { useDispatch } from 'react-redux';
import Logo from 'assets/logo.png';
import Alert from './Alert';

const { Header, Content, Footer, Sider } = Layout;

interface DashboardProps {
    children: ReactNode
}

const Dashboard = ({ children }: DashboardProps) => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const { actions } = useGlobalSliceSlice();
    const dispatch = useDispatch();

    // const { payload, loading } = useQuery(getRole());

    // useMemo(
    //     () => {
    //         if (!payload?.data || payload?.data?.permissions?.length == 0) return;
    //         const permissionUser = payload?.data?.permissions?.map(_per => _per?.name);

    //         dispatch(actions.saveRole({
    //             roles: permissionUser
    //         }));
    //     }, [payload]
    // );

    return (
        <LayoutProvider>
            <LayoutWrapper>
                <Layout>
                    <Header
                        className="site-layout-background site-layout-header"
                        style={{ position: 'fixed', zIndex: 1, width: '100%', boxShadow: '0 1px 9px -3px rgb(0 0 0 / 10%)', background: '#fff' }}
                    >
                        <Flex>
                            <div className='logo-wrapper' style={{ background: '#fff', boxShadow: '0 1px 9px -3px rgb(0 0 0 / 10%)' }}>
                                <img
                                    className="logo"
                                    src={Logo}
                                />
                            </div>
                            <MenuLayout />
                        </Flex>
                        <HeaderLayout />
                    </Header>
                    <Layout className='layout-content'>
                        <Alert />
                        {children}
                    </Layout>
                </Layout>
            </LayoutWrapper>
        </LayoutProvider>
    );
};

export default Dashboard;